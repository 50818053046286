import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setAuth,
  setDisplayRegister,
  setGuestAccount,
  setImageUrl,
  setUserEmail,
  setUserId,
  setUserName,
} from "../features/userSlice";
import { login } from "../services/auth";
import Input from "./common/Input";
import { DotLoader } from "react-spinners";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const LoginComponent = ({ customMessage }) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password) {
      setErrorMsg("All fields are required.");
      return;
    }

    try {
      setLoading(true);
      const response = await login({
        username,
        password,
      });
      if (response && response.error) {
        setErrorMsg(response.error);
      } else {
        setErrorMsg("");
        dispatch(setUserName(response.username));
        dispatch(setUserEmail(response.email));
        dispatch(setUserId(response.id));
        dispatch(setGuestAccount(false));
        dispatch(setAuth(true));
        dispatch(setImageUrl(response.imageUrl));
        dispatch(setDisplayRegister(false));
      }
    } catch (error) {
      setErrorMsg("An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div>
      <h2 className="reegistehg">Login</h2>
      <p className="text-xs text-center text-red-400">{customMessage}</p>
      <form className="hjjwej" onSubmit={handleLoginSubmit}>
        <Input
          name="username"
          type="text"
          placeholder="Enter Username"
          value={username}
          onChange={handleChangeUsername}
          required
          className="inputBar1"
        />
        <div className="floeuy">
          <Input
            name="password"
            type={passwordVisible ? "text" : "password"}
            placeholder="Enter Password"
            value={password}
            onChange={handleChangePassword}
            required
            className="inputBar1"
          />
          <div onClick={togglePasswordVisibility}>
            {passwordVisible ? (
              <EyeOffIcon className="eyeIcpn" />
            ) : (
              <EyeIcon className="eyeIcpn" />
            )}
          </div>
        </div>
        {errorMsg && <p className="error-message">{errorMsg}</p>}
        <div className="w-full flex justify-center items-center">
          <button type="submit" className="buttonkjk">
            {loading ? (
              <div className="sdhbc">
                <DotLoader
                  color={"#fff"}
                  loading={loading}
                  css={override}
                  size={20}
                />
              </div>
            ) : (
              <span>Login</span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PrivateRoute = () => {
  const location = useLocation();
  const token = new URLSearchParams(window.location.search).get("token");
  // If token is not present, redirect to "/"
  if (!token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // Otherwise, render the child components
  return <Outlet />;
};

export default PrivateRoute;

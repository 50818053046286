import React from "react";

function Input({ label, id, ...inputProps }) {
  return (
    <div className="mb-4">
      <label className="block mb-2 text-gray-300 font-medium" htmlFor={id}>
        {label}
      </label>
      <div>
        <input
          id={id}
          className="w-full resize-none bg-gray-700 text-white border border-gray-500 rounded-md py-2 px-4 leading-tight focus:outline-none focus:bg-gray-600 focus:border-indigo-500"
          {...inputProps}
        />
      </div>
    </div>
  );
}

export default Input;

import React, { forwardRef } from "react";
import "../styles/dropdownMenu.css";
import { deleteCookie } from "../utils/methods";
import { Link } from "react-router-dom";
import Gravatar from "react-gravatar";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout, setDisplayRegister } from "../features/userSlice";

const DropdownMenu = forwardRef((props, ref) => {
  const { userName, isAuth, credits, imageUrl } = useSelector(
    (state) => state.user
  );
  const hash = CryptoJS.MD5(userName).toString();
  const dispatch = useDispatch();
  const handleSignOut = (e) => {
    e.preventDefault();
    deleteCookie("token");
    dispatch(handleLogout());
    dispatch(setDisplayRegister(true));
  };

  return (
    <div ref={ref}>
      <div className="profile_dropdown">
        <div className="dropdown_section1">
          {imageUrl ? (
            <img src={imageUrl} alt="profile" className="user_frien3" />
          ) : (
            <Gravatar
              email={userName}
              size={150}
              default="identicon"
              className="profile_icon"
              hash={hash}
            />
          )}
          <div className="name_paln">
            <p className="icon_name text-ellipsis">{userName}</p>
            <p className="plan">Basic Plan</p>
          </div>
        </div>
        {isAuth ? (
          <div className="w-full flex justify-center my-2">
            <Link
              to={"/subscription"}
              className="rounded-full p-1 text-xs md:text-sm md:px-4 flex w-4/5 justify-center text-[#6cd97e] border-2 border-[#6cd97e]"
            >
              <span>Your Credits: </span>
              <span className="credit_numberss">{credits}</span>
            </Link>
          </div>
        ) : null}

        <hr className="line1" />
        <div className="dropdown_section2">
          <Link to="/profile" className="Profile_link">
            <p className="view_profile">View profile</p>
          </Link>
        </div>

        <hr className="line1" />
        <div className="dropdown_section4">
          <Link to="/subscription" className="Profile_link">
            <p className="view_profile">Pricing</p>
          </Link>
        </div>

        <hr className="line1" />
        <div className="dropdown_section4">
          {isAuth ? (
            <a
              href="#"
              className="view_profile"
              onClick={(e) => handleSignOut(e)}
            >
              {userName && userName.startsWith("guest_")
                ? "Sign in"
                : "Sign out"}
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
});
export default DropdownMenu;

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store/store";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = createRoot(document.getElementById("root"));
const query = new QueryClient();
if (process.env.REACT_APP_PROD_ENV === "true") {
  // console.log = () => {};
  // console.error = () => {};
  // console.warn = () => {};
}
const renderApp = () => (
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <HelmetProvider>
          <QueryClientProvider client={query}>
            <App />
          </QueryClientProvider>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            newestOnTop
            closeOnClick
            pauseOnFocusLoss
            draggable
            draggablePercent={60}
            closeButton={true}
            theme="light"
          />
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

root.render(renderApp());

reportWebVitals();

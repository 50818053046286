import React, { useEffect, Suspense } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Header from "./components/Header";
import ProtectedRoute from "./routes/ProtectedRoute";
import PrivateRoute from "./routes/PrivateRoute";
import { getCredits, getNotifications, updateFcmToken } from "./services/auth";
import "./App.css";
import { HashLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { setCredits, updateNotification } from "./features/userSlice";
import { toast } from "react-toastify";
import useGuestAuth from "./hooks/useGuestAuthentication";
import {
  generatePermission,
  messaging,
  onMessage,
  isBrowserEnvironment,
} from "./firebase-config";
import {
  trackPageNavigation,
  trackSessionStart,
  trackSessionEnd,
  resetIdleTimer,
  onPageNavigation,
  getUserId,
} from "./services/mixpanelService";
import PaymentSuccess from "./Payments/PaymentSuccess";

const Capabilities = React.lazy(() => import("./landingPages/Capabilities"));
const Blog = React.lazy(() => import("./landingPages/Blog"));
const BlogPage = React.lazy(() => import("./landingPages/blogsPage"));
const Privacy = React.lazy(() => import("./landingPages/privacyPage"));
const DeleteAccount = React.lazy(
  () => import("./landingPages/deleteAccountPage")
);
const SaveAssets = React.lazy(() => import("./socialFeature/SaveAssets"));
const NotificationPage = React.lazy(
  () => import("./socialFeature/NotificationPage")
);
const ToolsHome = React.lazy(() => import("./tools/ToolsHome"));
const WritingAssistant = React.lazy(
  () => import("./tools/writingTool/WritingAssistant")
);
const PlanGenerator = React.lazy(
  () => import("./tools/businessPlan/PlanGenerator")
);
const ViewPost = React.lazy(
  () => import("./socialFeature/socialPages/ViewPost")
);
const FlashCard = React.lazy(() => import("./tools/FlashCard"));
const HexCodeGenerator = React.lazy(() => import("./tools/hexCodeGenerator"));
const FlashCardResult = React.lazy(() => import("./tools/FlashCardResults"));
const BusinessAiTool = React.lazy(() => import("./tools/businessAiTool"));
const InterviewAi = React.lazy(() => import("./tools/InterviewAi"));
const InterviewQuestion = React.lazy(() => import("./tools/interviewQuestion"));
const VoiceAssistant = React.lazy(() => import("./pages/VoiceAssistant"));
const RealTimeImageGeneration = React.lazy(
  () => import("./tools/RealTimeTool")
);
const TutorHome = React.lazy(() => import("./tools/tutor/TutorHome"));
const WorkoutForm = React.lazy(() => import("./tools/workout/WorkoutForm"));
const WorkoutList = React.lazy(() => import("./tools/workout/WorkoutList"));
const Study = React.lazy(() => import("./tools/tutor/Study"));
const CaptionForm = React.lazy(() => import("./tools/caption/CaptionForm"));
const Result = React.lazy(() => import("./tools/caption/Result"));
const LogoMaker = React.lazy(() => import("./tools/logoMaker/LogoMaker"));
const MessagePage = React.lazy(
  () => import("./socialFeature/socialPages/MessagePage")
);
const VoiceAssistant2 = React.lazy(() => import("./pages/VoiceAssi2.0"));
const PromptLeaderboard = React.lazy(
  () => import("./socialFeature/promptLeaderboard")
);
const AvatarGenerator = React.lazy(
  () => import("./tools/AI_Tools/AvatarGenerator")
);
const Avatar3D = React.lazy(() => import("./tools/AI_Tools/3dAvatar"));
const AvatarModel = React.lazy(() => import("./pages/AvatarWebView"));
const HomeMainPage = React.lazy(() => import("./components/HomeMainPage"));
const GlobalAiNews = React.lazy(() => import("./tools/GlobalAiNews"));
const GlobalAiNewsDetail = React.lazy(
  () => import("./tools/GlobalAiNewsDetail")
);
const CreateAvatar = React.lazy(() => import("./pages/CreateAvatar"));
const MainAvatar = React.lazy(() => import("./pages/AvatarViewer"));
const AvatarYouAi = React.lazy(() => import("./components/AvatarYouAiChat"));
const AIChallenge = React.lazy(() => import("./pages/AIChallenge"));
const AIChallengeDetail = React.lazy(() => import("./pages/AIChallengeDetail"));
const InteractPost = React.lazy(
  () => import("./socialFeature/socialPages/InteractPost")
);
const Writer = React.lazy(() => import("./tools/novelWriting/Writer"));
const AvatarStore = React.lazy(() => import("./pages/AvatarStore"));
const Explore = React.lazy(() => import("./socialFeature/Explore"));
const SearchResults = React.lazy(
  () => import("./socialFeature/socialPages/SearchResults")
);
const ChangePassword = React.lazy(() => import("./pages/change-password"));
const Model = React.lazy(() => import("./pages/model"));
const Collection = React.lazy(() => import("./pages/collection"));
const Payments = React.lazy(() => import("./Payments/PaymentHome"));
const NotFound = React.lazy(() => import("./pages/404"));
const VoiceAssi = React.lazy(() => import("./pages/TTSpage"));
const AccountPage = React.lazy(() => import("./account/AccountMain"));
const HistoryStorage = React.lazy(
  () => import("./History_Components/History_main")
);
const MainChatbot = React.lazy(() => import("./chatbot/MainChatbot"));
const TrainingModels = React.lazy(
  () => import("./chatbot/components/TrainingModels")
);
const SocialMain = React.lazy(() => import("./socialFeature/SocialMain"));
const SocialProfile = React.lazy(() => import("./socialFeature/SocialProfile"));
const TrendingPage = React.lazy(() => import("./socialFeature/TrendingPage"));
const LandingHomePage = React.lazy(() => import("./landingPages/Home"));

const ChooseTemplatePage = React.lazy(
  () => import("../src/pages/ChooseTemplatePage")
);
const VideoEditorPage = React.lazy(
  () => import("../src/pages/VideoEditorPage")
);
const OutlineVideoPage = React.lazy(
  () => import("../src/pages/OutlineVideoPage")
);
const EditScriptPage = React.lazy(() => import("../src/pages/EditScriptPage"));
const GenerateVideoPage = React.lazy(
  () => import("../src/pages/GenerateVideoPage")
);

function App() {
  // const location = useLocation();

  // usePageTracking(); // Initialize page tracking

  const { userName, userId } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // Mixpanel services
  useEffect(() => {
    // Track page navigation whenever the location changes
    trackPageNavigation(userId);
    onPageNavigation(userId);
  }, []); // Dependency on location changes

  useEffect(() => {
    if (userId) {
      getUserId(userId);
      // Track session start when userId is available
      trackSessionStart(userId);

      // Listen for window unload to track session end
      window.addEventListener("beforeunload", () => trackSessionEnd(userId));

      // Initialize idle time detection
      resetIdleTimer();

      return () => {
        // Cleanup event listener when component unmounts
        window.removeEventListener("beforeunload", () =>
          trackSessionEnd(userId)
        );
      };
    }
  }, [userId]);

  const getUserCredits = async (source) => {
    const response = await getCredits(source);
    if (response?.data !== undefined) {
      dispatch(setCredits(response.data));
    }
  };
  const fetchNotifications = async () => {
    const response = await getNotifications();
    if (response && typeof response.count === "number") {
      dispatch(updateNotification(response.count));
    } else {
      console.error("Invalid count in response:", response);
    }
  };

  useEffect(() => {
    if (userName !== null && window.location.href !== "/") {
      getUserCredits();
      if (isBrowserEnvironment()) fetchNotifications();
    }
  }, [userName]);

  // GUEST USER CREATION
  useGuestAuth();

  useEffect(() => {
    const isBrowserEnv = isBrowserEnvironment();

    const requestPermissionAndUpdateToken = async () => {
      const storedToken = localStorage.getItem("fcmToken");
      const fcmToken = await generatePermission();
      if (fcmToken !== storedToken) {
        if (isBrowserEnv) await updateFcmToken({ fcmToken });
      }
    };
    if (isBrowserEnv) {
      requestPermissionAndUpdateToken();
      onMessage(messaging, (payload) => {
        if (
          payload.notification.data &&
          payload.notification.data.sender === "admin"
        ) {
          toast.info(payload.notification.title, {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            closeButton: false,
            style: {
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            },
          });
        } else {
          dispatch(updateNotification());
        }
      });
    }
  }, []);

  const isHeaderVisible =
    !window.location.pathname.includes("/blog") &&
    !window.location.pathname.includes("/home") &&
    !window.location.pathname.includes("/capabilities") &&
    !window.location.pathname.includes("/privacy") &&
    !window.location.pathname.includes("/avatarModel") &&
    !window.location.pathname.includes("/deleteAccount") &&
    !window.location.pathname.includes("/videoEditor");

  const FallBackLoader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "75vh",
        }}
      >
        <HashLoader color="#6cd97e" size={50} />
      </div>
    );
  };

  return (
    <div className="App">
      <BrowserRouter>
        {isHeaderVisible && <Header />}
        <Suspense fallback={<FallBackLoader />}>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/collection" element={<Collection />} />
              <Route path="/collection/:collection" element={<Model />} />
              <Route path="/history" element={<HistoryStorage />} />
              <Route path="/trainingModels" element={<TrainingModels />} />
              <Route path="/aichat/avatar" element={<MainChatbot />} />
              <Route path="/aichat/youai" element={<MainChatbot />} />
              <Route path="/aichat/aichallenge" element={<MainChatbot />} />
              <Route
                path="/aichat/avatar/:prevSessionId"
                element={<MainChatbot />}
              />
              <Route
                path="/aichat/youai/:prevSessionId"
                element={<MainChatbot />}
              />
              <Route
                path="/aichat/youai/:prevSessionId"
                element={<MainChatbot />}
              />

              <Route
                path="/aichat/aichallenge/:prevSessionId"
                element={<MainChatbot />}
              />
              <Route path="/social_profile" element={<SocialProfile />} />
              <Route path="/saveAssets" element={<SaveAssets />} />
              <Route path="/notification" element={<NotificationPage />} />
              <Route path="/profile" element={<AccountPage />} />
              <Route path="/VoiceAssi" element={<VoiceAssi />} />
              <Route
                path="/social/interact/:postId"
                element={<InteractPost />}
              />
              <Route path="/social/explore" element={<Explore />} />
              <Route path="/social/search" element={<SearchResults />} />
              <Route
                path="/promptLeaderboard"
                element={<PromptLeaderboard />}
              />
              <Route path="/chooseTemplate" element={<ChooseTemplatePage />} />
              <Route path="/videoEditor" element={<VideoEditorPage />} />
              <Route path="/outlineVideo" element={<OutlineVideoPage />} />
              <Route path="/editScript" element={<EditScriptPage />} />
              <Route path="/generateVideo" element={<GenerateVideoPage />} />

              <Route path="/" element={<HomeMainPage />} />
              <Route path="/createAvatar" element={<CreateAvatar />} />
              <Route
                path="/createAvatar/:avatarId"
                element={<CreateAvatar />}
              />
              <Route path="/avatarStore" element={<AvatarStore />} />
              <Route
                path="/aiChallengeDetail"
                element={<AIChallengeDetail />}
              />
              <Route path="/aiChallenge" element={<AIChallenge />} />
              <Route path="/avatarYouAi/:id" element={<AvatarYouAi />} />
              <Route
                path="/avatarYouAi/:id/:sessionId"
                element={<AvatarYouAi />}
              />
              <Route path="/avatarGenerator" element={<AvatarGenerator />} />
              <Route path="/avatar3d" element={<Avatar3D />} />
              <Route path="/avatarViewer" element={<MainAvatar />} />
              <Route path="/toptrends/:tag" element={<TrendingPage />} />
              <Route
                path="/social_profile/:username"
                element={<SocialProfile />}
              />
              <Route path="/messaging" element={<MessagePage />} />
              {/* ######################### AI TOOLS #################### */}
              <Route path="/tools" element={<ToolsHome />} />
              <Route
                path="/tools/writing-assistant"
                element={<WritingAssistant />}
              />
              <Route path="/tools/novel-writer" element={<Writer />} />
              <Route path="/tools/business-plan" element={<PlanGenerator />} />
              <Route path="/tools/tutor" element={<TutorHome />} />
              <Route path="/tools/tutor-study" element={<Study />} />
              <Route path="/tools/workout" element={<WorkoutForm />} />
              <Route path="/tools/workout-list" element={<WorkoutList />} />
              <Route path="/tools/flash_card" element={<FlashCard />} />
              <Route
                path="/tools/real-time-image"
                element={<RealTimeImageGeneration />}
              />
              <Route
                path="/tools/flash_card_results"
                element={<FlashCardResult />}
              />
              <Route
                path="/tools/hex_code_generator"
                element={<HexCodeGenerator />}
              />
              <Route
                path="/tools/business_ai_tool"
                element={<BusinessAiTool />}
              />
              <Route path="/tools/caption" element={<CaptionForm />} />
              <Route path="/tools/caption-result" element={<Result />} />
              <Route path="/tools/logo" element={<LogoMaker />} />
              <Route path="/voice_assistant" element={<VoiceAssistant />} />
              <Route path="/voice2" element={<VoiceAssistant2 />} />
              <Route path="/tools/interviewAi" element={<InterviewAi />} />
              <Route
                path="/tools/interviewQuestion"
                element={<InterviewQuestion />}
              />
            </Route>

            {/* UNPROTECTED ROUTES */}
            <Route path="/subscription" element={<Payments />} />
            <Route path="/payment" element={<Payments />} />
            <Route element={<PrivateRoute />}>
              <Route path="/success" element={<PaymentSuccess />} />
            </Route>
            <Route path="/social" element={<SocialMain />} />
            <Route path="/collection" element={<Collection />} />
            <Route path="/collection/:collection" element={<Model />} />
            <Route
              path="/globalAiNewsDetail/:id"
              element={<GlobalAiNewsDetail />}
            />
            <Route path="/globalAiNews" element={<GlobalAiNews />} />
            <Route
              path="/change-password/:reset_token?"
              element={<ChangePassword />}
            />
            <Route path="/social/:postId" element={<ViewPost />} />
            <Route path="/*" element={<NotFound />} />
            <Route path="/Home" element={<LandingHomePage />} />
            <Route path="/avatarModel" element={<AvatarModel />} />
            <Route path="/capabilities" element={<Capabilities />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blogPage/:id" element={<BlogPage />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/deleteAccount" element={<DeleteAccount />} />
            <Route path="/404" element={<NotFound />} />

            {/* 
              Don't know we need to keep this feature or not
              <Route path="/DashBoard" element={<DashBoard />} />
              <Route path="/myspace" element={<MySpace />} />
              <Route path="/dailyrecap" element={<Recap />} />
              <Route path="/goals" element={<DailyGoals />} />
              <Route path="/dailyrecord" element={<DailyRecord />} />
              
            */}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAXIFRgZqj4-zAiqdPwUNtKT0W7nNijhxA",
  authDomain: "ideaverse-58bc6.firebaseapp.com",
  projectId: "ideaverse-58bc6",
  storageBucket: "ideaverse-58bc6.firebasestorage.app",
  messagingSenderId: "1014050892996",
  appId: "1:1014050892996:web:811ae5c9fda6b761a56d91",
  measurementId: "G-HRFXKWGFMF",
  vapidKey:
    "BBIUGaLzio6dnCxKQ69TgjXNAP6Qjy6pgwf3BCJP7_RZQ-QuZwo_DFGvyJs3yl1LnJVKaW6TWXxxkmDwiVgsTkQ",
};

// Check if we're in a browser environment and not in React Native WebView
const isBrowserEnvironment = () => {
  return (
    typeof window !== "undefined" &&
    !window.ReactNativeWebView &&
    "Notification" in window
  );
};

// Initialize Firebase only in browser environment
let app;
let messaging;

if (isBrowserEnvironment()) {
  app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
}

const generateToken = async () => {
  if (!isBrowserEnvironment()) {
    console.log("Firebase messaging is not supported in this environment");
    return null;
  }

  try {
    const currentToken = await getToken(messaging, {
      vapidKey: firebaseConfig.vapidKey,
    });
    return currentToken;
  } catch (error) {
    console.log("Something went wrong in firebase", error);
    return null;
  }
};

const generatePermission = async () => {
  if (!isBrowserEnvironment()) {
    console.log("Notifications are not supported in this environment");
    return null;
  }

  try {
    // Helper function to generate and store a new token
    const generateAndStoreToken = async () => {
      const newToken = await generateToken();
      if (newToken && isBrowserEnvironment()) {
        localStorage.setItem("fcmToken", newToken);
      }
      return newToken;
    };

    // Retrieve stored permission from localStorage
    const storedPermission = isBrowserEnvironment()
      ? localStorage.getItem("notificationPermission")
      : null;

    // If permission is already granted
    if (storedPermission === "granted") {
      const storedToken = isBrowserEnvironment()
        ? localStorage.getItem("fcmToken")
        : null;

      if (storedToken) {
        return storedToken; // Return the stored token if available
      }

      // Generate a new token if none is stored
      return await generateAndStoreToken();
    }

    // Request notification permission
    const permission = await Notification.requestPermission();

    if (isBrowserEnvironment()) {
      localStorage.setItem("notificationPermission", permission);
    }

    // If permission is granted, generate a token
    if (permission === "granted") {
      return await generateAndStoreToken();
    } else {
      console.error("Notification permission denied.");
      return null;
    }
  } catch (error) {
    console.error("An error occurred while fetching the token.", error);
    return null;
  }
};

// Safe wrapper for onMessage
const safeOnMessage = (callback) => {
  if (isBrowserEnvironment()) {
    return onMessage(messaging, callback);
  }
  return () => {}; // Return empty cleanup function for non-browser environments
};

export {
  messaging,
  getToken,
  generatePermission,
  safeOnMessage as onMessage,
  isBrowserEnvironment,
};

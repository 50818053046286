import axios from "axios";
import { getCookie, setCookie } from "../utils/methods";
import {
  trackLogin,
  trackUserLogin,
  trackFirstWeekSession,
  checkNewUserRetentionSignUp,
  checkUserReturnAfterInactivity,
} from "../services/mixpanelService";
import apiService from "./apiService";
import baseUrl from "../baseUrl";
// import { setUser } from "../reduxFeatures/userSlice";
//Use a global variable for the backend url for ngrok
//let backend_url = "https://db6e-2405-204-a507-d852-5464-aa2a-4326-84a8.ngrok-free.app"

const apiUrl = `${baseUrl}/auth`;
const paymentApiUrl = `${baseUrl}/payment`;

// console.log(apiUrl);

// console.log("process.env.REACT_APP_APP_API", process.env.REACT_APP_APP_API);

const register = async (payload) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(`${apiUrl}/register`, payload, config);
    console.log("response from register", response.data);
    setCookie("token", response.data.user.token, 7);

    // Store the sign-up date in localStorage
    const userId = response.data.id; // Assuming you have user ID in the response
    const signUpDate = new Date().toISOString(); // Current date as sign-up date
    localStorage.setItem(`sign_up_date_${userId}`, signUpDate);

    // Call the function to check new user retention
    checkNewUserRetentionSignUp(userId);

    return response.data;
  } catch (error) {
    console.log("AI error", error);
    if (error?.response?.data?.message) {
      return { error: error.response.data.message };
    }
    return { error: error.message };
  }
};

const login = async (payload) => {
  try {
    const response = await axios.post(`${apiUrl}/login`, payload, {
      headers: {
        "ngrok-skip-browser-warning": true,
      },
    });

    setCookie("token", response.data.token, 7);
    trackFirstWeekSession(response.data.id); // Track session within the first week
    trackUserLogin(response.data.id); // Track user login
    const loginTime = new Date().getTime();

    localStorage.setItem(`login_timestamp_${response.data.id}`, loginTime);
    // Store last login date in localStorage
    localStorage.setItem(
      `last_login_date_${response.data.id}`,
      new Date().toISOString()
    );
    const currentSessionCount =
      parseInt(localStorage.getItem(`total_sessions_${response.data.id}`)) || 0;
    localStorage.setItem(
      `total_sessions_${response.data.id}`,
      currentSessionCount + 1
    );

    // Check for return after inactivity
    trackLogin(response.data.id);
    checkUserReturnAfterInactivity(response.data.id);
    return response.data;
  } catch (error) {
    console.log("AI error", error);
    if (error?.response?.data?.message) {
      return { error: error.response.data.message };
    }
    return { error: error.message };
  }
};

const requestPasswordChange = async (payload) => {
  try {
    const response = await axios.post(
      `${apiUrl}/request-password-change`,
      payload
    );
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return { error: error.response.data.message };
    }
    return { error: error.message };
  }
};

const resetPassword = async (payload) => {
  try {
    const response = await axios.post(`${apiUrl}/change-password`, payload);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return { error: error.response.data.message };
    }
    return { error: error.message };
  }
};

const updatePassword = async (payload) => {
  try {
    const response = await axios.patch(`${apiUrl}/update-password`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming token is stored in localStorage or another secure place
      },
    });
    return response;
  } catch (error) {
    if (error?.response?.data?.message) {
      return { error: error.response.data.message };
    }
    return { error: error.message };
  }
};

const getCredits = async () => {
  try {
    const token = getCookie("token");
    if (token !== undefined) {
      const response = await axios.get(`${paymentApiUrl}/available-credits`, {
        headers: {
          "auth-token": getCookie("token"),
        },
      });

      return response.data;
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      return { error: error.response.data.message };
    }
    return { error: error.message };
  }
};

const getNotifications = async () => {
  try {
    const response = await apiService.get(
      `${baseUrl}/auth/pending-notifications`
    );
    return response;
  } catch (error) {
    console.log(error.message);
    return error;
  }
};

const updateFcmToken = async (payload) => {
  try {
    const response = await apiService.put(
      `${baseUrl}/auth/update-fcmToken`,
      payload
    );
    return response;
  } catch (error) {
    console.log("Something went wrong");
    return { error: "Failed to update FCM token" };
  }
};

export {
  register,
  login,
  getNotifications,
  getCredits,
  requestPasswordChange,
  resetPassword,
  updatePassword,
  updateFcmToken,
};
